<!-- Toolbar -->
<div class="toolbar" style="background-color: black;" role="banner">

  <button type="button" style="background-color: transparent;
  border-color: transparent;" routerLink="/home">
    <img
    width="40"
    src="assets/logo.png" />
   </button>
  <a class="button" style="color: white;" routerLink="/home">Home</a>
  <div class="spacer"></div>
  <mat-form-field appearance="fill">
    <mat-select [value]="selectedOption">
      <mat-option *ngFor="let home of homes" [value]="home.idHome">
        {{home.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button type="submit" (click)="logout()">Logout</button>
</div>
<router-outlet></router-outlet>
