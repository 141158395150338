import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) {
    this.idRoom = this.activatedRoute.snapshot.params.idRoom;
  }
  idRoom: any
  sensors = [1,2,3]
  ngOnInit(): void {
  }

}
