import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { HttpClientModule } from '@angular/common/http';
import { ReadingsComponent } from './readings/readings.component';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSelectModule } from '@angular/material/select';
import { HomeComponent } from './home/home.component';
import { RoomComponent } from './room/room.component';
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { FormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { AccessGuard } from './access-guard';
import { UserHomeComponent } from './user-home/user-home.component';
import { HomeUserHomeService } from './user-home/home-user-home.service';

@NgModule({
  declarations: [
    AppComponent,
    ReadingsComponent,
    HomeComponent,
    RoomComponent,
    LoginComponent,
    RegisterComponent,
    UserHomeComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonToggleModule,
    MatSelectModule,
    FormsModule
  ],
  providers: [CookieService, AccessGuard, HomeUserHomeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
