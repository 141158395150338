import { Component, Input, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { Home } from '../data-types/home-type';
import { Room } from '../data-types/room-type';
import { Sensor } from '../data-types/sensor-type';
import { ReadingsService } from '../readings/readings.service';
import { HomeUserHomeService } from '../user-home/home-user-home.service';
import { HomeService } from './home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(public service: HomeService, private homeUserHomeService: HomeUserHomeService, private readingsService: ReadingsService) {
    this.homeUserHomeService.homeEvent$.subscribe(
      home => {
        this.home = home;
        this.updateHome();
      });
  }

  @Input() home?: Home;

  ngOnInit(): void {
    this.homeUserHomeService.getHome();
    interval(10000)
      .pipe(
        startWith(0),
        switchMap(async () => this.getData())
      )
      .subscribe({
        next(position) {
          //console.log('Current Position: ', position);
        },
        error(msg) {
          console.log('Error Getting Location: ', msg);
        }
     });
  }

  updateHome (){
    this.rooms = [];
    this.home?.rooms.forEach( (room) => {
      let nameIndx = room.points.indexOf(" ");
      let namePos = room.points.substring(0, nameIndx).split(",")
      let nameX = namePos[0];
      let nameY = namePos[1];
      let dataIndx = room.points.indexOf(" ", nameIndx + 1);
      let dataPos = room.points.substring(nameIndx, dataIndx).split(",");
      let sensors: { idSensor: string; name: string; x: string; y: string; value: string; unit: string; }[] = [];
      let x = Number(dataPos[0]) + 10;
      let y = Number(dataPos[1]) + 20;
      let orderedRoomSensors = room.sensors.sort((a: Sensor,b: Sensor) => a.sensorModel.sensorType.name.localeCompare(b.sensorModel.sensorType.name));
      orderedRoomSensors.forEach( (sensor) => {
        sensors.push({
          idSensor: sensor.idSensor.toString(),
          name: sensor.sensorModel.sensorType.name + ": ",
          x: x.toString(),
          y: y.toString(),
          value: "-",
          unit: this.generateUnit(sensor)
        });
        y += 15;
      });
      this.rooms.push({
        points: room.points.substring(nameIndx),
        name: {
          x: nameX,
          y: nameY,
          text: room.name
        },
        idRoom: room.idRoom,
        sensors: sensors
      });
    });

  }
  rooms: any;

  generateUnit(sensor: Sensor){
    switch(sensor.sensorModel.sensorType.idSensorType){
      case 1:
        return " ºC";
      case 2:
        return " %";
      default:
        return "";
    }

  }
  getData() {
    this.rooms?.forEach( (room: any) => {
      room.sensors.forEach( (sensor: any) => {
        this.readingsService.getLatestReadingByIdSensor(this.home!.idHome.toString(), room.idRoom, sensor.idSensor)
            .then((reading) => {
              sensor.value = reading.value;
            })
            .catch((e) => {
            });
      });
    });
  }

}
