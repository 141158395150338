import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Reading } from '../data-types/reading-type';
import { catchError } from 'rxjs/operators';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class ReadingsRestService {

  constructor(private http: HttpClient, private usersSevice: UsersService) { }

  private extractData(res: Response): any {
    const body = res;
    return body || { };
  }

  getReadingsByDate(from: Date, to: Date): Observable<Reading[]> {
    let params = new HttpParams();
    params= params.set("from", from.toISOString()) ;
    params= params.set("to", to.toISOString());
    return this.http.get<Reading[]>(environment.apiUrl + 'readings', {params: params});
  }

  getReadings(): Observable<Reading[]> {
    return this.http.get<Reading[]>(environment.apiUrl + 'readings');
  }

  getLatestReadingByIdSensor(idHome: string, idRoom: string, idSensor: string): Observable<Reading> {
    let headers = new HttpHeaders({
      "token": this.usersSevice.getToken()
    });
    const requestOptions = {
      headers: headers
    };
    return this.http.get<Reading>(environment.apiUrl + '/homes/'+ idHome +'/rooms/' + idRoom + '/sensors/' + idSensor + '/readings/latest', requestOptions);
  }

  addReading(reading: Reading): Observable<any> {
    return this.http.post<Reading>(environment.apiUrl + '/readings', reading).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }
}
