import { Injectable } from '@angular/core';
import {HomeRestService} from './home-rest.service.'
import { Home } from '../data-types/home-type';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private rest: HomeRestService) { }

  getHomes(): Promise<Home[]> {
    return new Promise(resolve => {
      this.rest.getHomes().subscribe((resp: Home[]) => {
        resolve(resp);
      });
    });
  }
}
