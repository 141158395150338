<mat-button-toggle-group style="float: right;" name="fontStyle" aria-label="Font Style" #group="matButtonToggleGroup" value="1h" (change)="intervalChange($event)">
  <mat-button-toggle value="1h">1h</mat-button-toggle>
  <mat-button-toggle value="4h">4h</mat-button-toggle>
  <mat-button-toggle value="12h">12h</mat-button-toggle>
  <mat-button-toggle value="24h">24h</mat-button-toggle>
  <mat-button-toggle value="2d">2d</mat-button-toggle>
  <mat-button-toggle value="1w">1w</mat-button-toggle>
  <mat-button-toggle value="1m">1m</mat-button-toggle>
</mat-button-toggle-group>
<ngx-charts-line-chart
  [view]="[1200,200]"
  [results]="values"
  [xAxisLabel]="'Time'"
  [yAxisLabel]="'Temperature'"
  [xScaleMin]="startDate"
  [xScaleMax]="endDate"
  [legend]="false"
  [showXAxisLabel]="true"
  [showYAxisLabel]="true"
  [xAxis]="true"
  [yAxis]="true"
  [timeline]="false"
  [gradient]="true">
</ngx-charts-line-chart>
