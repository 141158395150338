import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Home } from '../data-types/home-type';
import { HomeService } from '../home/home.service';
import { UsersService } from '../users/users.service';
import { HomeUserHomeService } from './home-user-home.service';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit {

  constructor(public userService: UsersService, private homeService: HomeService, private homeUserHomeService: HomeUserHomeService, public router: Router) {
    this.homeUserHomeService.parentEvent$.subscribe(
      () => {
        this.loadHomes();
      });
   }

  selectedOption?: String
  ngOnInit() {
    //this.loadHomes();
  }

  homes?: Home[];

  loadHomes(){
    this.homeService.getHomes().then((homes) => {
      this.homes = homes;
      this.selectedOption = this.homes![0].idHome as unknown as string;
      this.homeUserHomeService.loadHome(this.homes![0]);
    })
    .catch((e) => {
    });
  }


  logout() {
    this.userService.logout();
    this.router.navigateByUrl('/');
  }
}
