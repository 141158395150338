import { RoomComponent } from './room/room.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { AccessGuard } from './access-guard';
import { AppComponent } from './app.component';
import { UserHomeComponent } from './user-home/user-home.component';

 const routes: Routes = [
  { path: 'home', component: UserHomeComponent, data:{requiresLogin: true}, canActivate: [ AccessGuard ],
    children: [
      { path: '', component: HomeComponent, data:{requiresLogin: true}, canActivate: [ AccessGuard ]},
      { path: 'room/:idRoom', component: RoomComponent, data:{requiresLogin: true}, canActivate: [ AccessGuard ]},
    ]},
  { path: "login", component: LoginComponent, pathMatch: "full", data:{requiresLogin: false}, canActivate: [ AccessGuard ]},
  { path: "register", component: RegisterComponent, pathMatch: "full", data:{requiresLogin: false}, canActivate: [ AccessGuard ]},
  {path: '', redirectTo: '/login', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
