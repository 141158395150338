import { group } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { ReadingsService } from './readings.service';


@Component({
  selector: 'app-readings',
  templateUrl: './readings.component.html',
  styleUrls: ['./readings.component.scss']
})
export class ReadingsComponent implements OnInit {

  constructor(public service: ReadingsService) {
    this.setupInterval();
   }

  @Input() interval: String = "1h";
  @Input() startDate: Date = new Date();
  @Input() endDate: Date = new Date();

  lastUpated: Date = new Date();
  series: any

  calcInterval(){
    this.startDate = new Date();
    this.endDate = new Date();
    switch(this.interval){
      case "1h":
        this.startDate.setHours(this.startDate.getHours()-1);
        break;
      case "4h":
        this.startDate.setHours(this.startDate.getHours()-4);
        break;
      case "12h":
        this.startDate.setHours(this.startDate.getHours()-12);
        break;
      case "24h":
        this.startDate.setHours(this.startDate.getHours()-24);
        break;
      case "2d":
        this.startDate.setDate(this.startDate.getDate()-2);
        break;
      case "1w":
        this.startDate.setDate(this.startDate.getDate()-7);
        break;
      case "1m":
        this.startDate.setMonth(this.startDate.getMonth()-1);
        break;

    }
  }
  setupInterval(){
    this.calcInterval();
    this.lastUpated = this.startDate;
    this.series = []
  }
  values: any;


  intervalChange(event: MatButtonToggleChange){
    this.interval = event.value;
    this.setupInterval();
  }


  getData() {
    this.calcInterval();
    var callTime = new Date();
    this.service.getReadingsByDate(this.lastUpated, callTime)
    .then((readings) => {
      for (let i = 0; i < readings.length; i++) {
        this.series.push({value: readings[i].value, name: readings[i].timestamp});
      }
      this.values = [{
        "name": "Temperaturas",
        "series": this.series
      }];
      var a = 1;
      this.lastUpated = callTime;
    })
    .catch((e) => {
    });
  }

  ngOnInit() {
    interval(10000)
      .pipe(
        startWith(0),
        switchMap(async () => this.getData())
      )
      .subscribe({
        next(position) {
          //console.log('Current Position: ', position);
        },
        error(msg) {
          console.log('Error Getting Location: ', msg);
        }
     });}

}
