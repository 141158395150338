import { HomeService } from './home/home.service';
import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UsersService } from './users/users.service';
import { Home } from './data-types/home-type';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sensors-front';
  constructor() {

  }

}


