<!-- login.component.html -->
<!-- Toolbar -->
<div class="toolbar" style="background-color: black;" role="banner">

  <button type="button" style="background-color: transparent;
  border-color: transparent;" routerLink="/home">
    <img
    width="40"
    src="assets/logo.png" />
   </button>
  <div class="spacer"></div>
</div>


<div class="login">
  <form class="custom-form" method="post">
    <h1>Login</h1>
    <input
      type="email"
      [(ngModel)]="email"
      name="email"
      placeholder="Email"
      required="required"
    />
    <input
      type="password"
      [(ngModel)]="password"
      name="password"
      placeholder="Password"
      required="required"
    />
    <button type="submit" (click)="login()">Log in</button>
  </form>
</div>
