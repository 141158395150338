import { stringify } from "@angular/compiler/src/util";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";

@Injectable()
export class AccessGuard implements CanActivate {
  constructor(private cookies: CookieService, private router: Router){

  }

  validCookieSet(){
    let cookie =  this.cookies.get("token");
    return cookie != null && cookie.length > 0;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    const requiresLogin = route.data.requiresLogin || false;
    if (requiresLogin) {
     if(!this.validCookieSet()){
      this.router.navigate(['/login']);
     }
    } else {
      if (route.url[0].path == "login" && this.validCookieSet()) {
        this.router.navigate(['/home']);
      }
    }
    return true;
  }
}
