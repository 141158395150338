<!-- register.component.html -->

<div class="register">
  <form class="custom-form" method="post">
    <h1>Register</h1>
    <input
      type="email"
      [(ngModel)]="email"
      name="email"
      placeholder="Email"
      required="required"
    />
    <input
      type="password"
      [(ngModel)]="password"
      name="password"
      placeholder="Password"
      required="required"
    />
    <input
      type="password"
      [(ngModel)]="confirmPassword"
      name="password"
      placeholder="Repeat the password"
      required="required"
    />
    <button type="submit" (click)="register()">Register</button>
  </form>
</div>
