import { Home } from '../data-types/home-type';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HomeUserHomeService {
  private homeEvents = new Subject<Home>();
  private parentEvent = new Subject();
  homeEvent$ = this.homeEvents.asObservable();
  parentEvent$ = this.parentEvent.asObservable();

  loadHome(home: Home) {
    this.homeEvents.next(home);
  }

  getHome() {
    this.parentEvent.next();
  }
}
