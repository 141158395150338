import { Injectable } from '@angular/core';
import {ReadingsRestService} from './readings-rest.service.'
import { Reading } from '../data-types/reading-type';

@Injectable({
  providedIn: 'root'
})
export class ReadingsService {

  constructor(private rest: ReadingsRestService) { }

  getReadingsByDate(from: Date, to: Date): Promise<Reading[]> {
    return new Promise(resolve => {
      this.rest.getReadingsByDate(from, to).subscribe((resp: Reading[]) => {
        resp.forEach((reading) => {reading.timestamp = new Date(reading.timestamp)})
        resolve(resp);
      });
    });
  }

  getReadings(): Promise<Reading[]> {
    return new Promise(resolve => {
      this.rest.getReadings().subscribe((resp: Reading[]) => {
        resp.forEach((reading) => {reading.timestamp = new Date(reading.timestamp)})
        resolve(resp);
      });
    });
  }

  getLatestReadingByIdSensor(idHome: string, idRoom: string, idSensor: string): Promise<Reading> {
    return new Promise(resolve => {
      this.rest.getLatestReadingByIdSensor(idHome, idRoom, idSensor).subscribe((resp: Reading) => {
        resp.timestamp = new Date(resp.timestamp)
        resolve(resp);
      });
    });
  }

  addReading(reading: Reading): Promise<any> {
    return new Promise(resolve => {
      this.rest.addReading(reading).subscribe((resp: any) => {
        resolve(resp);
      });
    });
  }
}
