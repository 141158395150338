import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Home } from '../data-types/home-type';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class HomeRestService {

  constructor(private http: HttpClient, private usersSevice: UsersService) { }

  getHomes(): Observable<Home[]> {
    let headers = new HttpHeaders({
      "token": this.usersSevice.getToken()
    });
    const requestOptions = {
      headers: headers
    };
    return this.http.get<Home[]>(environment.apiUrl + '/homes', requestOptions);
  }
}
